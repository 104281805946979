// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: black;
}

iframe
{
    display: none;
}

.gamblin-jack-font {
  font-family: "Great Vibes", cursive !important;
  font-weight: 800;
  font-style: normal;
}

.playfair {
  font-family: "Playfair Display", serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  /* Example: replaced <weight> with an actual value */
  font-style: normal;
}

.notable-regular {
  font-family: "Notable", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.bungee-shade-regular {
  font-family: "Bungee Shade", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.miltonian-regular {
  font-family: "Miltonian", serif !important;
  font-weight: 400;
  font-style: normal;
}

.bungee-spice-regular {
  font-family: "Bungee Spice", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.honk {
  font-family: "Honk", system-ui !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "MORF" 15,
    "SHLN" 50;
}

.grandstander {
  font-family: "Grandstander", cursive !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;EACE,8CAA8C;EAC9C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iDAAiD;EACjD,yBAAyB;EACzB,gBAAgB;EAChB,oDAAoD;EACpD,kBAAkB;AACpB;;AAEA;EACE,6CAA6C;EAC7C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kDAAkD;EAClD,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;EAC1C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kDAAkD;EAClD,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB;;aAEW;AACb;;AAEA;EACE,+CAA+C;EAC/C,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["body {\n  background-color: black;\n}\n\niframe\n{\n    display: none;\n}\n\n.gamblin-jack-font {\n  font-family: \"Great Vibes\", cursive !important;\n  font-weight: 800;\n  font-style: normal;\n}\n\n.playfair {\n  font-family: \"Playfair Display\", serif !important;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  /* Example: replaced <weight> with an actual value */\n  font-style: normal;\n}\n\n.notable-regular {\n  font-family: \"Notable\", sans-serif !important;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.bungee-shade-regular {\n  font-family: \"Bungee Shade\", sans-serif !important;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.miltonian-regular {\n  font-family: \"Miltonian\", serif !important;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.bungee-spice-regular {\n  font-family: \"Bungee Spice\", sans-serif !important;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.honk {\n  font-family: \"Honk\", system-ui !important;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  font-style: normal;\n  font-variation-settings:\n    \"MORF\" 15,\n    \"SHLN\" 50;\n}\n\n.grandstander {\n  font-family: \"Grandstander\", cursive !important;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
